import { Col, Container, Row } from "react-bootstrap";
import "./devices.scss";
import FadeIn from "../FadeIn";

import DeviceAnimation from "../DeviceAnimation/DevaiceAnimation";

export default function Devices() {
  return (
    <section id="responsywnosc">
      <Container>
        <FadeIn>
          <Row className="devices-heading">
            <Col className="devices-heading--left">
              <h1 className="text-right">Jedna strona</h1>
            </Col>
            <Col className="devices-heading--right">
              <h1>Wiele urządzeń</h1>
            </Col>
          </Row>
        </FadeIn>
        <FadeIn>
          <Row>
            <Col md>
              <div className="grid-center">
                <DeviceAnimation />
              </div>
            </Col>
            <Col md>
              <div className="h-100 grid-center">
                <div>
                  <p>
                    Ponad 55% ruchu w internecie odbywa się za pośrednictwem
                    urządzeń mobilnych.
                  </p>
                  <p>
                    Dlatego ważnym jest, aby każda strona była poprawnie
                    wyświetlana na każdym rozmiarze ekranu.
                  </p>
                  <p>
                    Korzystając z moich usług nie musisz się o to martwić.
                    Otrzymasz ode mnie stronę responsywną, dostosowywującą się
                    do każdego ekranu.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </FadeIn>
      </Container>
    </section>
  );
}
