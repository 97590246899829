import { Col, Container, Row } from "react-bootstrap";
import FadeIn from "../FadeIn";
import TextMove from "../TextMove/TextMove";
import laptop from "../../assets/images/laptop.png";
import "./heading.scss";

export default function Heading() {
  return (
    <section id="start" navid="start">
      <div className="heading-gradient-bg"></div>
      <Container className="h-100 position-relative">
        <Row className="h-100">
          <Col md className="grid-center">
            <div className="heading-left-content text-md-left text-center">
              <h1 className="text-gradient">
                <b>Make IT Real</b>
              </h1>

              <h2 className="text-xl">Kamil Kasperek</h2>

              <TextMove />

              <div>
                <a className="cta-button" href="#o-mnie">
                  SAM SIĘ PRZEKONAJ
                </a>
              </div>
            </div>
          </Col>
          <Col className="d-none d-md-block">
            <div className="h-100 grid-center">
              <img
                src={laptop}
                alt="make-it-real-laptop"
                className="img-fluid"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
