import { Container } from "react-bootstrap";
import "./nextLevel.scss";
import FadeIn from "../FadeIn";
import arrowUrl from "../../assets/images/arrows.png";

export default function NextLevel() {
  return (
    <section id="o-mnie" navid="o-mnie" className="about">
      <Container>
        <FadeIn>
          <div className="about__next-level text-center">
            <h1 className="text-7xl text-gradient">
              PRZENIEŚ SWÓJ BIZNES NA WYŻSZY POZIOM&nbsp;!
            </h1>
            <div className="about__arrow">
              <img src={arrowUrl} alt="next-level" className="img-fluid" />
            </div>
            <h3>
              Pomogę Ci w realizacji <b>Twojego</b> sukcesu
            </h3>
          </div>
        </FadeIn>
      </Container>
    </section>
  );
}
