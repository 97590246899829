import { Container } from "react-bootstrap";

import "./contact.scss";
import FadeIn from "../FadeIn";
import ContactForm from "../ContactForm/ContactForm";

export default function Contact() {
  return (
    <section id="kontakt" navid="kontakt">
      <Container>
        <FadeIn>
          <h1 className="text-center">Skontaktuj się ze mną</h1>
        </FadeIn>
        <FadeIn>
          <div className="contact">
            <div className="contact-form-container">
              <ContactForm />
            </div>

            <b className="contact__serparator">LUB</b>

            <a className="cta-button" href="tel:733-960-628">
              ZADZWOŃ TERAZ
            </a>
          </div>
        </FadeIn>
      </Container>
      <Container></Container>
    </section>
  );
}
