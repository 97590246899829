import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import EmailService from "../../services/email.service";
import "./contactForm.scss";

const requiredMsg = "To pole jest wymagane";

const SignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Imię musi zawierać przynajmniej 2 znaki")
    .max(20, "Imię nie może być dłuższe niż 20 znaków")
    .required(requiredMsg),
  content: Yup.string()
    .min(10, "Treść musi zawierać przynajmniej 10 znaków")
    .max(200, "Treść nie może być dłuższa niż 20  0 znaków")
    .required(requiredMsg),
  email: Yup.string().email("Nieprawidłowy adres email").required(requiredMsg),
  phone: Yup.number(),
});

export default function ContactForm() {
  return (
    <div className="contact-form">
      <Formik
        initialValues={{
          name: "",
          content: "",
          email: "",
          phone: "",
        }}
        validationSchema={SignupSchema}
        onSubmit={(values) => {
          const { name, phone, email, content } = values;
          EmailService.sendEmail(name, phone, email, content)
            .then()
            .catch(console.error);
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div>
              <label htmlFor="name">Imię</label>
              <Field name="name"></Field>
              <div className="contact-form__error__container">
                <div className="contact-form__error">
                  {errors.name && touched.name ? errors.name : " "}
                </div>
              </div>
            </div>

            <div>
              <label htmlFor="email">Email</label>
              <Field name="email"></Field>
              <div className="contact-form__error__container">
                <div className="contact-form__error">
                  {errors.email && touched.email ? errors.email : " "}
                </div>
              </div>
            </div>

            <div>
              <label htmlFor="phone">Telefon</label>
              <Field name="phone"></Field>
              <div className="contact-form__error__container">
                <div className="contact-form__error">
                  {errors.phone && touched.phone ? errors.phone : " "}
                </div>
              </div>
            </div>

            <div>
              <label htmlFor="name">Treść</label>
              <Field name="content" as="textarea"></Field>
              <div className="contact-form__error__container">
                <div className="contact-form__error">
                  {errors.content && touched.content ? errors.content : " "}
                </div>
              </div>
            </div>

            <button className="transparent-button" type="submit">
              WYŚLIJ
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
