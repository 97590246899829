import { Col, Container, Row } from "react-bootstrap";
import "./footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

library.add(faPhone, faEnvelope, faLinkedin);

export default function Footer() {
  return (
    <footer id="footer">
      <Container>
        <Row>
          <Col md>
            <ul>
              <li style={{ marginBottom: "0.5rem" }}>
                <strong>Make IT Real Kamil Kasperek</strong>
              </li>
              <li>NIP: 6443559732</li>
              <li>REGON: 388733124</li>
            </ul>
          </Col>
          <Col md>
            <ul>
              <li style={{ marginBottom: "0.5rem" }}>
                <strong>Kontakt</strong>
              </li>
              <li>
                <FontAwesomeIcon icon={["fas", "phone"]} />
                733960628
              </li>
              <li>
                <a href="mailto: kasperek.dev@gmail.com">
                  <FontAwesomeIcon icon={["fas", "envelope"]} />
                  kasperek.dev@gmail.com
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/kamil-kasperek-1a670a191/"
                  aria-label="Kamil Kasperek linkedin"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={["fab", "linkedin"]} />
                  Linkedin
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
