import { Container } from "react-bootstrap";
import "./technologies.scss";
import FadeIn from "../FadeIn";

export default function Technologies() {
  return (
    <section id="technologie" navid="technologie">
      <Container className="text-center">
        <FadeIn>
          <h1>Technologie, w których pracuję</h1>
        </FadeIn>
        <FadeIn>
          <div className="stack">
            <div>
              <img
                className="stack__img img-fluid"
                src="https://angular.io/assets/images/logos/angular/angular.png"
                alt="angular"
              />
              <a
                href="https://angular.io/"
                target="_blank"
                rel="noreferrer"
                className="stack__name"
              >
                ANGULAR
              </a>
            </div>

            <div>
              <img
                className="stack__img img-fluid"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/2300px-React-icon.svg.png"
                alt="react"
              />
              <a
                href="https://pl.legacy.reactjs.org/"
                target="_blank"
                rel="noreferrer"
                className="stack__name"
              >
                REACT
              </a>
            </div>

            <div>
              <img
                className="stack__img img-fluid"
                src="https://static-00.iconduck.com/assets.00/node-js-icon-1817x2048-g8tzf91e.png"
                alt="nodejs"
              />
              <a
                href="https://nodejs.org/en/about"
                target="_blank"
                rel="noreferrer"
                className="stack__name"
              >
                NODE JS
              </a>
            </div>

            <div>
              <img
                className="stack__img img-fluid"
                src="https://www.pngall.com/wp-content/uploads/13/Mongodb-PNG-Image-HD.png"
                alt="mongodb"
              />
              <a
                href="https://www.mongodb.com/"
                target="_blank"
                rel="noreferrer"
                className="stack__name"
              >
                MONGO DB
              </a>
            </div>
          </div>
        </FadeIn>
      </Container>
    </section>
  );
}
