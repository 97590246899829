import axios from "axios";

const EmailService = {
  sendEmail: (name, phone, email, content) => {
    return axios.post("/api/sendEmail", {
      name,
      phone,
      email,
      content,
    });
  },
};

export default EmailService;
