import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "./navbar.scss";
import logo from "../../assets/images/logo.png";

var scrollEvent = null;

function changeNavBg(root, nav) {
  const { y } = root.getClientRects()[0];
  if (y === 0) nav.classList.remove("scrolled");
  if (y !== 0) nav.classList.add("scrolled");
}

function setNavLinksActive(sections, navHeight, setActiveLink) {
  sections.forEach((sec) => {
    const top = window.scrollY;
    const offset = sec.offsetTop - navHeight * 2;
    const height = sec.offsetHeight;
    const id = sec.getAttribute("id");

    if (top >= offset && top < offset + height) {
      setActiveLink(id);
    }
  });
}

function watchScroll(setActive) {
  if (scrollEvent) return;

  const root = document.getElementById("root");
  const nav = document.getElementById("nav");
  const sections = Array.from(document.getElementsByTagName("section")).filter(
    (section) => section.getAttribute("navid")
  );
  const navHeight = nav.clientHeight;

  window.addEventListener("scroll", () => {
    changeNavBg(root, nav);
    setNavLinksActive(sections, navHeight, setActive);
  });

  scrollEvent = true;
}

export default function Navigation() {
  const startId = "start";
  const [active, setActive] = useState(startId);

  useEffect(() => watchScroll(setActive), []);
  return (
    <Navbar fixed="top" expand="lg" id="nav" collapseOnSelect>
      <Container>
        <Navbar.Brand href="#start">
          <img
            src={logo}
            alt="make-it-real-logo"
            className="nav-logo img-fluid"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="me-auto"
            activeKey={active}
            onSelect={(selectedKey) => setActive(selectedKey)}
          >
            <Nav.Link href="#start" eventKey={startId}>
              Start
            </Nav.Link>

            <Nav.Link href="#o-mnie" eventKey="o-mnie">
              O mnie
            </Nav.Link>

            <Nav.Link href="#technologie" eventKey="technologie">
              Technologie
            </Nav.Link>

            <Nav.Link href="#jak-pracuje" eventKey="jak-pracuje">
              Jak pracuję
            </Nav.Link>

            <Nav.Link href="#kontakt" eventKey="kontakt">
              Kontakt
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
