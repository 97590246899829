import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMedal } from "@fortawesome/free-solid-svg-icons";

import "./yourNeeds.scss";
import FadeIn from "../FadeIn";

library.add(faMedal);

export default function YourNeeds() {
  return (
    <section id="twoje-potrzeby">
      <Container>
        <Row>
          <Col md>
            <div className="h-100 grid-center">
              <FadeIn>
                <h2 className="text-center text-md-left">
                  Strona internetowa szyta na miarę
                  <b className="text-gradient"> Twoich potrzeb</b>
                </h2>
              </FadeIn>
            </div>
          </Col>
          <Col md className="mt-5 mt-md-0">
            <FadeIn>
              <div className="box box--y-violet-borders">
                <p className="text-center">
                  <FontAwesomeIcon icon={["fas", "medal"]} size="3x" />
                </p>
                <p>
                  Tworzone przeze mnie aplikacje budowane są za pomocą
                  najnowszych technologi webowych.
                </p>
                <p>
                  Nie korzystam z gotowców, czy szablonów. Każda moja strona
                  tworzona jest od podstaw, tak aby spełnić wszystkie
                  <b> Twoje</b> wymagania
                </p>
              </div>
            </FadeIn>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
