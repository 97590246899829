import { useEffect } from "react";
import "./text-move.css";

var interval = null;

function consoleText(words, id, colors) {
  if (colors === undefined) colors = ["#fff"];

  var visible = true;
  var con = document.getElementById("console");
  var letterCount = 1;
  var x = 1;
  var waiting = false;
  var target = document.getElementById(id);
  var speed = 100;

  target.setAttribute("style", "color:" + colors[0]);

  if (!interval) {
    interval = window.setInterval(function () {
      if (letterCount === 0 && waiting === false) {
        waiting = true;
        target.innerHTML = words[0].substring(0, letterCount);
        window.setTimeout(function () {
          var usedColor = colors.shift();
          colors.push(usedColor);
          var usedWord = words.shift();
          words.push(usedWord);
          x = 1;
          target.setAttribute("style", "color:" + colors[0]);
          letterCount += x;
          waiting = false;
        }, 1000);
      } else if (letterCount === words[0].length + 1 && waiting === false) {
        waiting = true;
        window.setTimeout(function () {
          x = -1;
          letterCount += x;
          waiting = false;
        }, 1000);
      } else if (waiting === false) {
        target.innerHTML = words[0].substring(0, letterCount);
        letterCount += x;
      }
    }, 100);
    window.setInterval(function () {
      if (visible === true) {
        con.className = "console-underscore console-underscore--hidden";
        visible = false;
      } else {
        con.className = "console-underscore";

        visible = true;
      }
    }, speed);
  }
}

export default function TextMove() {
  useEffect(() => {
    consoleText(["WIEDZA", "JAKOŚĆ", "DOŚWIADCZENIE"], "console-text", [
      "var(--violet)",
      "var(--white)",
      "var(--darker-violet)",
    ]);
  });
  return (
    <div style={{ fontSize: "20px" }}>
      <span id="console-text"></span>
      <div className="console-underscore" id="console">
        &#95;
      </div>
    </div>
  );
}
