import "./deviceAnimation.scss";

export default function DeviceAnimation() {
  return (
    <div className="device-animation">
      <div className="screen">
        <div className="screen__interior"></div>
        <div className="screen__dot"></div>
      </div>
      <div className="leg"></div>
      <div className="stand"></div>
    </div>
  );
}
