import "./App.scss";
import Contact from "./components/Contact/Contact";
import Devices from "./components/Devices/Devices";
import Footer from "./components/Footer/Footer";
import Heading from "./components/Heading/Heading";
import HowIWork from "./components/HowIWork/HowIWork";
import Navigation from "./components/Navbar/Navbar";
import NextLevel from "./components/NextLevel/NextLevel";
import Technologies from "./components/Technologies/Technologies";
import YourNeeds from "./components/YourNeeds/YourNeeds";

function App() {
  return (
    <>
      <Navigation />

      <Heading />

      <NextLevel />

      <YourNeeds />

      <Devices />

      <Technologies />

      <HowIWork />

      <Contact />

      <Footer />
    </>
  );
}

export default App;
