import { Container } from "react-bootstrap";
import "./howIWork.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPeopleArrows,
  faPersonDigging,
  faRankingStar,
} from "@fortawesome/free-solid-svg-icons";
import FadeIn from "../FadeIn";

library.add(faPeopleArrows, faPersonDigging, faRankingStar);

const stepsData = [
  {
    number: 1,
    title: "PROJEKT / WYCENA",
    icon: "people-arrows",
    desc: ["wstępna rozmowa", "wyznaczenie celu", "stworzenie projektu"],
  },
  {
    number: 2,
    title: "REALIZACJA",
    icon: "person-digging",
    desc: [
      "inicjalizacja projektu",
      "przemiana kawy w kod",
      "ewentualne poprawki",
    ],
  },
  {
    number: 3,
    title: "ZADOWOLENIE",
    icon: "ranking-star",
    desc: ["wdrożenie", "efekt WOW", "PROFIT"],
  },
];

function Step({ step }) {
  const listItems = step.desc.map((item, i) => <p key={i}>{item}</p>);
  return (
    <div className="step">
      <div className="step__circle grid-center">
        <span className="text-gradient">{step.number}</span>
      </div>
      <p className="step__title">{step.title}</p>

      <p className="text-center">
        <FontAwesomeIcon icon={["fas", step.icon]} size="3x" />
      </p>

      <div className="text-center">{listItems}</div>
    </div>
  );
}

export default function HowIWork() {
  const steps = stepsData.map((step) => <Step key={step.number} step={step} />);

  return (
    <section id="jak-pracuje" navid="jak-pracuje">
      <FadeIn>
        <h1 className="text-center">Jak pracuję ?</h1>
      </FadeIn>
      <Container>
        <FadeIn>
          <div className="steps">{steps}</div>
        </FadeIn>
      </Container>
    </section>
  );
}
